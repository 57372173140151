<template>
  <div class="fullHeight">
    <el-skeleton :loading="showSkeleton" animated class="fullHeight">
      <template slot="template" class="fullHeight">
        <div class="blog-item haha" style="style">
          <div class="mainContainer">
            <div class="topContainer">
              <div class="titleContainer" style="height:50px;margin-right: 20px;">
                <el-skeleton-item variant="text" class="title" style="height: 20px;" />
                <el-skeleton-item variant="text" class="category" style="height: 20px;" />
              </div>
              <div class="logoContainer">
                <el-skeleton-item variant="image" class="logo" />
              </div>
            </div>
            <div class="bottomContainer" style="display: flex; align-items: left; justify-content: space-between;flex-direction: column;
              flex-wrap: wrap;height: 150px;">
              <el-skeleton-item variant="text" style="height: 20px;" />
              <el-skeleton-item variant="text" style="height: 20px;" />
              <el-skeleton-item variant="text" style="height: 20px;" />
              <el-skeleton-item variant="text" style="height: 20px;" />
              <el-skeleton-item variant="text" style="height: 20px;width: 30%;" />
            </div>
          </div>
        </div>
      </template>
      <template class="fullHeight">
        <!-- 这里不包一层div好像样式渲染有问题 这里必须用内联样式，优先级高点，我也不知道是啥原因 -->
        <div class="fullHeight" style="height:100%;">
          <div class="blog-item fullHeight" :style="style">
            <div class="mainContainer">
              <a @click="blogClick">
                <div class="topContainer">
                  <div class="titleContainer">
                    <p class="title textEllipsis">{{ blogItem.title }}</p>
                    <p class="category font">{{ blogItem.category }}</p>
                  </div>
                  <div class="logoContainer">
                    <img class="logo" src="~@/assets/img/swift.jpg" alt="" />
                  </div>
                </div>
                <div class="bottomContainer">
                  <p class="textEllipsis font digest">
                    {{ blogItem.digest }}
                  </p>
                  <span class="articleTime font">{{ blogItem.date }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>

      </template>
    </el-skeleton>
  </div>

</template>

<script>
  import {
    CURRENT_BLOGID,
    CURRENT_INDEX,
    CURRENT_BLOGITEM
  } from "../store/mutation-types";

  export default {
    name: "blogItem",
    data() {
      return {
        style: {}
      };
    },
    props: {
      blogItem: {},
      index: Number,
      showSkeleton: {
        type: Boolean,
        required: true,
        default: true
      }
    },
    methods: {
      blogClick() {
        // 保存文章到store中
        this.$store.commit(CURRENT_BLOGID, this.blogItem.objectId);
        this.$store.commit(CURRENT_BLOGITEM, this.blogItem);
        this.$store.commit(CURRENT_INDEX, this.index);
        this.$router.push({
          path: "/detail/" + this.blogItem.objectId
        });
      },
    },
  };
</script>

<style scoped lang='less'>
  .font {
    font-family: "SF Pro SC", "SF Pro Display", "SF Pro Icons", "PingFang SC",
      "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    color: #6e7687;
    font-size: 1.1em;
    line-height: 1.45;
  }

  .blog-item {
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.07);
  }

  .fullHeight {
    height: 100%;
  }

  .mainContainer {
    padding: 20px 20px;
    height: 100%;
  }

  a {
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
  }

  .digest {
    flex: 5;
  }

  .articleTime {
    flex: 1.5;
  }

  a:hover {
    cursor: pointer;
  }

  .topContainer {
    flex: 1;
    display: flex;
  }

  .title {
    font-family: "SF Pro SC", "SF Pro Display", "SF Pro Icons", "PingFang SC",
      "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1.23em;
    font-weight: 700;
    color: #333333;
    margin-bottom: 5px;
    line-height: 1.2;
  }

  .titleContainer {
    flex: 3;
  }

  .logoContainer {
    flex: 1;
  }

  .logo {
    flex: 1;
    border-radius: 8px;
    width: 70px;
    height: 70px;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    flex: 5;
    margin-top: 20px;
  }

  @media (max-width: 750px) {
    .blog-item {
      height: 220px;
      padding: 5px;
      border-radius: 10px;
      margin-bottom: 5px;
    }

    a {
      height: 220px;
    }

    .logo {
      height: 50px;
      width: 50px;
    }


  }
</style>