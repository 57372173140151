import {request} from "./request";

export default function loadMoreBlogs(blog) {
  return request({
    url: '/classes/blogItem',
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-LC-Id': 'r57JSq9yQU4WHkBh3WlJktM0-gzGzoHsz',
      'X-LC-Key': '6B1HmkLavgUf3SKXAGziVUhK'
      },
    params: blog
  })
}